<template>
  <header class="py-10">
    <div class="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
      <nav class="flex justify-between">
        <div class="flex items-center">
          <NuxtLink href="https://promptdrive.ai" class="flex items-center">
            <SiteLogo class="w-12 h-12 rounded-md mr-4" v-if="false" />
            <img src="~/assets/pd-logo-text.png" class="h-8" />
          </NuxtLink>
        </div>
        <div><BaseButton href="/register" tag="a">Sign Up</BaseButton></div>
      </nav>
    </div>
  </header>
</template>
